<template>
	<div class="pageContainer">
		<web-header></web-header>
		<!-- <div class="main">
			<router-title :menuList="menuList[0]" :list="menuList[1]" :list1="menuList[2]" />
		</div>
		<div class="content main">
			<div class="contentOne">
				<div class="contentOneLeft">
					<div class="leftTop">
						<img :src="courseForm.coverPicture" alt="">
						<h2>
							<span>{{!!courseForm.examType && courseForm.examType === 1 ? '自考' : '统考'}}</span>
							<span>{{courseForm.name}}</span>
						</h2>
						<p>培训时间：{{courseForm.studyStartTime}}</p>
						<p v-if="courseForm.typeId == 1">学时：{{courseForm.classHours}}个学时</p>
						<p v-else>学分：{{courseForm.classHours}}个学分</p>
						<div v-if="courseForm.isPay === 0" @click="clickHandle(courseForm)">立即购买</div>
						<div v-else-if="courseForm.isPay === 1">已购买</div>
					</div>
					<div class="leftBottom">
						<h5>培训介绍</h5>
						<p>
							{{courseForm.introduce}}
						</p>
					</div>
				</div>
				<div class="contentOneRight">
					<div class="list" v-if="sectionList.length > 0">
						<div class="item" v-for="(it,i) in sectionList" :key="i">
							<div class="item_l">
								<span class="cicle"></span>
								<div class="name" :title="it.name">{{i+1}}.{{it.name}}</div>
							</div>
							<div class="item_r">
								<div class="progress" >
									<el-progress :stroke-width="12" color="#FDA331" :percentage="it.studyTime" />
								</div>
								<div class="type" v-if="courseForm.isPay === 0" @click="toShiKan(it)">试看</div>
								<div class="type" v-if="courseForm.isPay != 0 && it.studyTime >= 100"
									@click="handlePracticeClass(it)">答题</div>
								<div class="time">
									<img src="../../assets/index/button.png" alt="" @click="toShiKan(it)">
									<span>{{timeData(it.duration)}}</span>
								</div>
							</div>
						</div>
					</div>

					<div class="noTeacter" v-else-if="sectionList.length === 0">
						<div class="noTeacter_img">
							<img src="../../assets/imgs/4.png" alt="">
							<div>抱歉，暂时没有章节哦</div>
						</div>
					</div>
				</div>
			</div>
			<div class="contentThree">
				<h3>培训评论</h3>
				<p>
					<span>留下你的评论</span>
					<span>
						<el-rate v-model="commentValue" show-text>
						</el-rate>
					</span>
				</p>
				<div>
					<el-input type="textarea" placeholder="请输入内容" v-model="comment" maxlength="500" show-word-limit
						:rows="4">
					</el-input>
				</div>
				<div class="buttonCls" @click="commentEvt">
					发表
				</div>
				<div class="allPing">全部评价({{commentList.length}})</div>
				<div class="pinglun" v-for="(it,i) in commentList" :key="i">
					<div class="touxiang">
						<img :src="it.photo" alt="">
					</div>
					<div style="margin-left:15px;flex:2;">
						<p>
							<span>{{it.stuName}}</span>
							<span style="pointer-events: none">
								<el-rate v-model="it.evaluation" :disabled="false">
								</el-rate>
							</span>
							<span class="timeClass">{{it.createTime}}</span>
						</p>
						<p>{{it.comment}}</p>
					</div>
				</div>
			</div>
		</div> -->
		<div class="content">
			<div class="header">
				<div class="header_box">
					<router-title style="margin-top:0" :menuList="menuList[0]" :list="menuList[1]"
						:list1="menuList[2]" />
					<div class="info">
						<div class="info_l">
							<img :src="courseForm.coverPicture" alt="">
						</div>
						<div class="info_r">
							<div class="name">{{courseForm.name}}</div>
							<div class="status">
								<div class="type">{{courseForm.examType && courseForm.examType === 1 ? '自考' : '统考'}}
								</div>
								<div class="btn pay_btn" @click="clickHandle(courseForm)"
									v-if="verifyCourseStatus(courseForm.studyEndTime,courseForm.isPay) === 1">立即购买</div>
								<div class="btn pay_btn"
									v-if="verifyCourseStatus(courseForm.studyEndTime,courseForm.isPay) === 2">已购买</div>
								<div class="btn"
									v-if="verifyCourseStatus(courseForm.studyEndTime,courseForm.isPay) === 3">已结束</div>
							</div>
						</div>
					</div>
					<div class="tabs">
						<div :class="['tab_item',{'active_tab':currentTabIndex === 0}]" @click="currentTabIndex = 0">详情
						</div>
						<div :class="['tab_item',{'active_tab':currentTabIndex === 1}]" @click="currentTabIndex = 1">目录
						</div>
					</div>
				</div>
			</div>
			<div class="main">
				<div class="main_l" v-show="currentTabIndex === 0">
					<div class="item evaluate_item">
						<div class="title">课程评价</div>
						<div class="score">
							<span>留下您的评论</span>
							<el-rate v-model="commentValue" show-text />
						</div>
						<el-input type="textarea" placeholder="请输入内容" v-model="comment" maxlength="500" show-word-limit
							:rows="4" />
						<div class="btn" @click="commentEvt">评论</div>
					</div>
					<div class="item all_score">
						<div class="all_title">全部评论({{courseCommentsList.length}})</div>
						<div class="score_item" v-for="(item,index) in currentCommentsList" :key="index">
							<div class="item_l">
								<img :src="item.photo" alt="">
							</div>
							<div class="item_r">
								<div class="item_r_t">
									<div class="item_r_t_l">
										<span>{{item.stuName}}</span>
										<el-rate v-model="item.evaluation" disabled />
									</div>
									<div class="item_r_t_r">{{item.createTime}}</div>
								</div>
								<div class="item_r_b">{{item.comment}}</div>
							</div>
						</div>
						<div class="view_btn" v-if="isAllCommentsMore" @click="handleLoadCommentMore">查看更多</div>
					</div>
				</div>
				<div class="main_l" v-show="currentTabIndex === 1">
					<div class="chapter_item" v-for="(item,index) in sectionList" :key="index">
						<div class="chapter_t">
							<div class="name" :title="item.name">
								<span v-text="index < 9 ? `0${index+1}` : `${index+1}`"></span>
								<span>{{item.name}}</span>
							</div>
							<div class="operate" v-if="!courseForm.isPay || courseForm.isPay === 0" @click="toShiKan(item)">试看</div>
							<div class="operate" v-if="courseForm.isPay === 1 && item.studyTime < 100"
								@click="toShiKan(item)">观看</div>
							<div class="operate" v-if="courseForm.isPay === 1 && item.studyTime >= 100" @click="handlePracticeClass(item)">答题</div>
						</div>
						<div class="chapter_b">
							<img src="../../assets/index/button.png" width="14" alt="" @click="toShiKan(item)">
							<span class="duration">{{timeData(item.duration)}}</span>
							<div class="progress">
								<el-progress :stroke-width="13" color="#FDA331" :percentage="item.studyTime" />
							</div>
						</div>
					</div>
				</div>
				<div class="main_r">
					<div class="item details_item">
						<div class="title">课程详情</div>
						<div class="time">培训开始时间:{{courseForm.studyStartTime}}</div>
						<div class="time">培训结束时间:{{courseForm.studyEndTime}}</div>
						<div class="hours" v-if="courseForm.typeId == 1">学时:{{courseForm.classHours}}个学时</div>
						<div class="hours" v-else>学分:{{courseForm.classHours}}个学分</div>
					</div>
					<div class="item introduce_item">
						<div class="title">培训介绍</div>
						<div class="introduce">{{courseForm.introduce}}</div>
					</div>
				</div>
			</div>
		</div>
		<web-footer></web-footer>
	</div>
</template>

<script>
	import WebFooter from '../../components/webFooter.vue'
	import webHeader from '../../components/webHeader.vue'
	import RouterTitle from "../../components/routerTitle.vue";
	export default {
		components: {
			webHeader,
			WebFooter,
			RouterTitle
		},
		data() {
			return {
				currentTabIndex: 0,
				menuList: ["首页", "培训项目", "课程详情"],
				imgList: [{
						url: ''
					},
					{
						url: '1'
					},
					{
						url: '2'
					},
				],
				textarea: '',
				comment: '',
				value: 0,
				commentValue: 0,
				commentList: [],
				courseForm: {},
				sectionList: [],
				id: '',
				peixunxinde: '',
				// 获取章节视频的总共时间
				duration: '',
				orderId: '', //订单ID
				commentsSize: 5, //默认显示的评论数
				isAllCommentsMore: false, //是否有更多评论
				courseCommentsList: [], //全部评价
				currentCommentsList: [], //当前显示的评价
			}
		},
		created() {
			this.id = this.$route.query.id
			this.orderId = this.$route.query.orderId
			//获取单个课程信息
			this.$http.post('getCourseInfoById', {
				id: this.id
			}).then(res => {
				if (res.code === 200) {
					this.courseForm = res.data
					console.log(this.courseForm, 'this.courseForm ');
					// console.log(this.courseForm,'------');
				} else {
					this.$message.error(res.message)
				}
			})
			//获取章节信息
			this.$http.post('getCourseSectionById', {
				id: this.id,
			}).then(res => {
				if (res.code === 200) {

					this.sectionList = res.data || []

				} else {
					this.$message.error(res.message)
				}
			})
			//获取课程评论
			this.commentListEvt()
		},
		methods: {
			// 点击加载更多评论
			handleLoadCommentMore() {
				const loading = this.$loading({
					lock: true,
					text: "Loading",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				let currentSize = this.currentCommentsList.length,
					nextCommentsSize = currentSize + this.commentsSize;
				setTimeout(() => {
					this.currentCommentsList = this.currentCommentsList.concat(this.courseCommentsList.slice(
						currentSize, nextCommentsSize))
					loading.close()
				}, 300)
				if (this.courseCommentsList.length <= nextCommentsSize) {
					this.isAllCommentsMore = false
				}
			},
			// 培训课程校验 1 未购买 2 已购买 3已结束
			verifyCourseStatus(time, isPay) {
				let status = 0;
				if (Date.parse(time) < new Date()) {
					status = 3
				} else {
					if (isPay == 1) status = 2
					else status = 1
				}
				return status
			},
			// 时间转换
			timeData(time) {
				var hh;
				var mm;
				var ss;
				//传入的时间为空或小于0
				if (time == null || time < 0) {
					return;
				}
				//得到小时
				hh = time / 3600 | 0;
				time = parseInt(time) - hh * 3600;
				if (parseInt(hh) < 10) {
					hh = "0" + hh;
				}
				//得到分
				mm = time / 60 | 0;
				//得到秒
				ss = parseInt(time) - mm * 60;
				if (parseInt(mm) < 10) {
					mm = "0" + mm;
				}
				if (ss < 10) {
					ss = "0" + ss;
				}
				// if (hh == '00') return mm + ":" + ss
				// if (hh == '00' && mm == '00') return ss
				return hh + ":" + mm + ":" + ss;
			},

			//去试看页面
			toShiKan(it) {
				//判断是否支持试看
				this.$http.post('studyCourseApi', {
					courseId: this.id,
					sectionId: it.id,
				}).then(res => {
					if (res.code === 200) {
						//是否可以试看
						if (!res.data.id) {
							this.$message.success(`当前视频可观看${res.data.tryTime}秒`)
							setTimeout(() => {
								this.$router.push({
									path: '/trainingProgram/courseViewing',
									query: {
										id: this.id,
										sectionId: it.id,
										//试看
										status: this.courseForm.isPay,
										// 可以试看的时间
										time: res.data.tryTime,
										// 防挂机时间
										hangTime: this.courseForm.antiHook === 1 ? this.courseForm
											.hangTime : 0,
										data: it,
										// 数据
										listCourse: this.courseForm
									}
								})
							}, 1500)
						} else {
							this.$router.push({
								path: '/trainingProgram/courseViewing',
								query: {
									id: this.id,
									sectionId: it.id,
									status: this.courseForm.isPay,
									time: res.data.watchTheTime,
									// 防挂机时间
									hangTime: this.courseForm.antiHook === 1 ? this.courseForm.hangTime :
										0,
									data: it,
									// 数据
									listCourse: this.courseForm,
									orderId: this.courseForm.orderId //订单Id
								}
							})
						}
					} else {
						this.$message.error(res.message)
					}
				})
			},
			// 进入课后练习
			handlePracticeClass(e) {
				localStorage.setItem('zjieId', e.id)
				localStorage.setItem('coureId', e.courseId)
				this.$http.post('mineTest', {
					chapterId: e.id,
					courseId: e.courseId
				}).then(res => {
					if (res.code == 200) {
						this.$router.push({
							path: '/examination/afterClassTest'
						})
					} else {
						this.$message.warning(res.message)
					}
				})
			},
			// 立即购买
			clickHandle(e) {
				localStorage.setItem('cousreId', e.id)
				this.$router.push({
					path: '/trainingProgram/paymentOrder',
					query: {
						index: 3,
						type: 2,
						data: e
					}
				})
			},
			//发布培训心得
			peixunEvt() {
				this.$http.post('peixunAdd', {
					courseId: this.id,
					courseNotes: this.peixunxinde
				}).then(res => {
					const loading = this.$loading({
						lock: true,
						text: '发表心得中...',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					})
					if (res.code === 200) {
						this.peixunxinde = ''
						this.$message.success('发表心得成功')
					} else {
						this.$message.error(res.message)
					}
					loading.close()
				})
			},
			commentListEvt() {
				//获取课程评论
				this.$http.post('getCourseCommentById', {
					id: this.id
				}).then(res => {
					if (res.code === 200) {
						// console.log(res,'----1-----');
						// this.commentList = res.data
						this.courseCommentsList = res.data
						if (res.data.length > this.commentsSize) {
							this.currentCommentsList = res.data.slice(0, this.commentsSize);
							this.isAllCommentsMore = true
						} else {
							this.currentCommentsList = res.data;
						}
					} else {
						this.$message.error(res.message)
					}
				})
			},
			//发表评论
			commentEvt() {
				if (!!this.comment) {
					if (!!this.commentValue) {
						this.$http.post('addComment', {
							courseId: this.id,
							comment: this.comment,
							evaluation: this.commentValue
						}).then(res => {
							const loading = this.$loading({
								lock: true,
								text: '发表评论中...',
								spinner: 'el-icon-loading',
								background: 'rgba(0, 0, 0, 0.7)'
							});
							if (res.code === 200) {
								this.comment = ''
								this.commentValue = 0
								this.$message.success('评论成功')
								this.commentListEvt()
							} else {
								this.$message.error(res.message)
							}
							loading.close()
						})
					} else {
						this.$message.error('请为当前评论打分')
					}
				} else {
					this.$message({
						type: 'error',
						message: '评论不能为空！',
						offset: 300,
					})
				}
			}

		}
	}
</script>

<style lang="less" scoped>
	.content {
		.header {
			height: 336px;
			background: linear-gradient(180deg, #FFCE1C 0%, #FF891D 100%);
			padding: 30px;

			.header_box {
				width: 1130px;
				margin: 0 auto;

				.info {
					display: flex;

					.info_l {
						width: 201px;
						height: 159px;
						border-radius: 10px;

						img {
							width: 100%;
							height: 100%;
							border-radius: 10px;
						}
					}

					.info_r {
						flex: 1;
						margin-left: 12px;

						.name {
							font-size: 18px;
							font-family: PingFang SC;
							font-weight: bold;
							color: #FFFFFF;
							margin: 18px 0 14px;
						}

						.status {
							display: flex;
							align-items: center;
							justify-content: space-between;

							.type {
								width: 66px;
								height: 28px;
								line-height: 26px;
								border: 1px solid #FFFFFF;
								border-radius: 4px;
								font-size: 14px;
								font-family: PingFang SC;
								font-weight: 500;
								color: #FFFFFF;
								text-align: center;
							}

							.btn {
								width: 120px;
								height: 45px;
								border-radius: 4px;
								font-size: 18px;
								font-family: PingFang SC;
								font-weight: 400;
								color: #FFFFFF;
								background: #ccc;
								text-align: center;
								line-height: 45px;
							}

							.pay_btn {
								background: linear-gradient(106deg, #FF8A1D 0%, #FF321D 100%);
							}

						}
					}
				}

				.tabs {
					display: flex;
					align-items: center;
					margin-top: 38px;

					.tab_item {
						font-size: 18px;
						font-family: PingFang SC;
						font-weight: 500;
						color: #FFFFFF;
						cursor: pointer;
						height: 26px;
						line-height: 26px;

						&:first-child {
							margin-left: 37px;
						}

						&:last-child {
							margin-left: 51px;
						}
					}

					.active_tab {
						position: relative;

						&::after {
							content: '';
							display: block;
							width: 20px;
							height: 3px;
							background: #fff;
							position: absolute;
							bottom: -10px;
							left: 50%;
							transform: translateX(-50%);
							cursor: default;
						}

					}
				}
			}
		}

		.main {
			display: flex;
			width: 1130px;
			margin: 0 auto;
			min-height: 500px;
			max-height: 900px;
			overflow-y: auto;

			.item {
				.title {
					font-size: 18px;
					font-family: PingFang SC;
					font-weight: bold;
					color: #333333;
					display: flex;
					align-items: center;
					margin-bottom: 25px;

					&::before {
						content: '';
						display: block;
						width: 4px;
						height: 17px;
						background: #FBAF25;
						border-radius: 2px;
						margin-right: 3px;
					}
				}
			}

			.main_l {
				flex: 9;
				margin-right: 30px;
				padding: 30px;

				.evaluate_item {
					.score {
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 500;
						color: #666666;
						display: flex;
						align-items: center;
						margin-bottom: 10px;

						span {
							margin-right: 18px;
						}
					}

					.btn {
						float: right;
						margin-top: 10px;
						width: 66px;
						height: 28px;
						line-height: 28px;
						text-align: center;
						background: #E51E0B;
						border-radius: 4px;
						font-size: 16px;
						font-family: PingFang SC;
						font-weight: 500;
						color: #FFFFFF;
						cursor: pointer;
					}
				}

				.all_score {
					margin-top: 76px;

					.all_title {
						width: 112px;
						height: 44px;
						line-height: 42px;
						text-align: center;
						border: 1px solid #FBAF25;
						border-radius: 4px;
						font-size: 16px;
						font-family: PingFang SC;
						font-weight: 500;
						color: #FBAF25;
					}

					.score_item {
						display: flex;
						margin-top: 40px;

						.item_l {
							width: 46px;
							height: 46px;
							border-radius: 50%;

							img {
								width: 100%;
								height: 100%;
								border-radius: 50%;
								display: block;
							}
						}

						.item_r {
							flex: 1;
							margin-left: 20px;

							.item_r_t {
								display: flex;
								align-items: center;
								justify-content: space-between;

								.item_r_t_l {
									display: flex;
									align-items: center;
									font-size: 16px;
									font-family: PingFang SC;
									font-weight: bold;
									color: #333333;

									span {
										margin-right: 10px;
									}
								}

								.item_r_t_r {
									font-size: 14px;
									font-family: PingFang SC;
									font-weight: 500;
									color: #333333;
								}
							}

							.item_r_b {
								font-size: 14px;
								font-family: PingFang SC;
								font-weight: 400;
								color: #333333;
								margin-top: 26px;
							}
						}
					}

					.view_btn {
						width: 89px;
						height: 34px;
						border: 1px solid #FBAF25;
						border-radius: 4px;
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #FBAF25;
						text-align: center;
						line-height: 32px;
						margin: 30px auto 0;
						cursor: pointer;
					}
				}

				.chapter_item {
					margin-bottom: 30px;

					.chapter_t {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding-bottom: 16px;

						.name {
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							width: 560px;

							span {
								font-size: 16px;
								font-family: PingFang SC;
								font-weight: bold;
								color: #333333;

								&:first-child {
									color: #999999;
									margin-right: 27px;
								}
							}

						}

						.operate {
							width: 47px;
							height: 22px;
							border: 1px solid #FF0000;
							line-height: 20px;
							border-radius: 11px;
							font-size: 12px;
							font-family: PingFang SC;
							font-weight: 400;
							color: #E51E0B;
							text-align: center;
							cursor: pointer;
						}
					}

					.chapter_b {
						display: flex;
						align-items: center;
						margin-left: 50px;
						height: 30px;
						line-height: 30px;

						img {
							cursor: pointer;
						}

						.duration {
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 400;
							color: #999999;
							margin-left: 8px;
							padding-right: 11px;
							border-right: 1px solid #999999;
						}

						.progress {
							width: 150px;
							margin-left: 24px;
						}
					}
				}
			}

			.main_r {
				flex: 4;
				padding: 30px;

				.details_item {

					.time,
					.hours {
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #999999;
						margin-bottom: 19px;
					}
				}

				.introduce_item {
					.introduce {
						font-size: 16px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #666666;
						text-indent: 2em;
					}
				}
			}
		}
	}

	// .pageContainer {
	// 	background: #eee;
	// }

	// .main {
	// 	width: 1200px;
	// 	margin: auto;
	// }

	// .content {
	// 	margin-bottom: 65px;
	// }

	.contentOne {
		display: flex;
		justify-content: space-between;
		margin-bottom: 25px;

		.contentOneLeft {
			.leftTop {
				width: 501px;
				background: #FFFFFF;
				box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.13);
				padding: 25px;

				img {
					width: 451px;
					height: 249px;
					display: block;
				}

				h2 {
					display: flex;
					margin: 35px 0;
					// overflow: hidden;
					// text-overflow: ellipsis;
					// white-space: nowrap;

					span:nth-child(1) {
						width: 64px;
						height: 27px;
						background: #FF8276;
						border-radius: 14px;
						font-size: 16px;
						font-family: PingFang SC;
						font-weight: 500;
						color: #FFFFFF;
						display: block;
						line-height: 27px;
						text-align: center;
						margin-top: 4px;
					}

					span:nth-child(2) {
						display: block;
						font-size: 24px;
						font-family: PingFang SC;
						font-weight: 500;
						color: #333333;
						margin-left: 15px;
					}
				}

				p {
					font-size: 18px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #999999;
					margin-bottom: 12px;
				}

				div {
					width: 159px;
					height: 49px;
					background: #E51E0B;
					font-size: 18px;
					font-family: PingFang SC;
					font-weight: 500;
					color: #FFFFFF;
					text-align: center;
					line-height: 49px;
					margin-top: 38px;
					cursor: pointer;
				}
			}

			.leftBottom {
				margin-top: 25px;
				width: 501px;
				height: 371px;
				background: #FFFFFF;
				box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.13);
				padding: 25px;

				h5 {
					font-size: 18px;
					font-family: PingFang SC;
					font-weight: bold;
					color: #E51E0B;
					margin-bottom: 25px;
					margin-top: 0;
				}

				p {
					font-size: 16px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #333333;
					line-height: 1.8;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 9;
					overflow: hidden;
				}
			}
		}

		.contentOneRight {
			// width: 881px;
			margin-left: 30px;
			flex: 2;
			height: 949px;
			background: #FFFFFF;
			box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.13);
			padding: 32px;
			overflow-y: scroll;

			.list {
				.item {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-bottom: 24px;
					border-left: 1px solid #E51E0B;

					&:last-child {
						padding-bottom: 0;
					}

					.item_l {
						display: flex;
						padding-left: 16px;
						position: relative;

						.cicle {
							position: absolute;
							display: block;
							width: 5px;
							height: 5px;
							background: #E51E0B;
							border-radius: 50%;
							left: -3px;
						}

						.name {
							font-size: 16px;
							color: #333333;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							width: 240px;

						}
					}

					.item_r {
						display: flex;
						align-items: center;

						.progress {
							margin-right: 60px;
							width: 120px;

							// display: flex;
							// align-items: center;
							span {
								font-size: 12px;
								color: #333333;
								margin-left: 37px;
							}
						}

						.type {
							margin-right: 18px;
							text-align: center;
							width: 47px;
							height: 21px;
							line-height: 21px;
							border: 1px solid #FF0000;
							border-radius: 11px;
							font-size: 12px;
							color: #E51E0B;
							cursor: pointer;
						}

						.time {
							display: flex;
							align-items: center;

							img {
								width: 16px;
								height: 16px;
								margin-right: 10px;
								cursor: pointer;
							}

							font-size: 16px;
							color: #999999;
						}
					}
				}
			}



			.rightOne {
				border-left: 1px solid#E51E0B;
			}

			.cardCls {
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #333333;
				margin-bottom: 22px;
				position: relative;
				padding-left: 15px;

				.nameStyle {
					width: 400px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				.jindu {
					width: 100px;
					position: absolute;
					right: 200px;
					top: -8px;
				}

				.cicle {
					display: block;
					width: 5px;
					height: 5px;
					background: #E51E0B;
					border-radius: 50%;
					position: absolute;
					left: -3px;
					top: 0;
				}

				.shikan {
					position: absolute;
					right: 100px;
					top: -8px;
					width: 47px;
					height: 21px;
					border: 1px solid #FF0000;
					border-radius: 11px;
					text-align: center;
					line-height: 21px;
					font-size: 12px;
					font-family: PingFang SC;
					font-weight: bold;
					color: #E51E0B;
					cursor: pointer;
				}

				.time {
					position: absolute;
					right: 0;
					top: -6px;
					display: flex;
					line-height: 1;
					cursor: pointer;

					span {
						display: block;

						img {
							width: 16px;
							height: 16px;
							margin-right: 10px;
						}
					}
				}
			}
		}
	}

	.contentTwo {
		min-height: 300px;
		background: #FFFFFF;
		box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.13);
		margin: 25px 0;
		padding: 25px;

		h3 {
			font-size: 18px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #E51E0B;
			margin-bottom: 30px;
		}

		p {
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #333333;
			margin: 18px 0;
			display: flex;

			span {
				img {
					display: block;
					width: 16px;
					height: 19px;
					margin-right: 5px;
				}
			}
		}

		.buttonCls {
			width: 110px;
			height: 49px;
			background: #E51E0B;
			text-align: center;
			line-height: 49px;
			font-size: 18px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #FFFFFF;
			margin-top: 20px;
			// margin-left: 1250px;
			cursor: pointer;
		}
	}

	.contentThree {
		background: #FFFFFF;
		box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.13);
		padding: 25px;

		h3 {
			font-size: 18px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #E51E0B;
		}

		p {
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #333333;
			margin: 18px 0;
			display: flex;

			span {
				margin-right: 10px;

				/deep/.el-icon-star-on {
					color: #E51E0B !important;
				}
			}
		}

		.buttonCls {
			// display: flex;
			width: 110px;
			height: 49px;
			background: #E51E0B;
			text-align: center;
			line-height: 49px;
			font-size: 18px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #FFFFFF;
			margin-top: 20px;
			margin-left: 1040px;
			cursor: pointer;
		}

		.allPing {
			width: 112px;
			height: 44px;
			border: 1px solid #E51E0B;
			margin: 12px;
			font-size: 15px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #E51E0B;
			text-align: center;
			line-height: 44px;
		}

		.pinglun {
			display: flex;

			.touxiang {
				width: 46px;
				height: 46px;
				border-radius: 50%;
				margin-top: 15px;

				img {
					width: 100%;
					height: 100%;
					border-radius: 50%;
				}
			}

			p {
				// width: 1300px;
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #333333;
				position: relative;

				span {
					margin-right: 15px;
				}

				.timeClass {
					position: absolute;
					right: 0;
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 500;
					color: #333333;
				}
			}
		}
	}

	.noTeacter {
		// margin-left: 45%;
		color: #666;
		height: 60vh;
		text-align: center;
		margin-top: 200px;
		font-size: 14px;
		color: #ccc;

		.noTeacter_img {
			img {
				width: 188px;
				height: 141px;
				margin-bottom: 10px;
			}
		}
	}
</style>
